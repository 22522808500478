<template>
	<DefaultGrid>
		<template #sidebar>
			<SidebarLogo />
			<ClientOnly>
				<SidebarNav
						v-if="route.meta.hideSidebarNav !== true"
						:authenticated="store.authenticated"
						:company-id="companyId"
						:rie-id="rieId"
						:company-name="companyName"
						:rie-name="rieName"
						:allowed-to-write-to-company="userDetailsStore.isAllowedToWriteToCompany"
						:allowed-to-write-to-rie="userDetailsStore.isAllowedToWriteToRie"
						:loading="store.loading"
						:login-url="store.loginUrl ?? undefined"
				/>
			</ClientOnly>
		</template>

		<template #default>
			<slot v-if="route.meta.allowSSR"></slot>
			<ClientOnly v-else>
				<div v-if="keycloak.state.value === KeycloakState.Failed">
					Fout tijdens het authenticeren, probeer het later opnieuw.
				</div>
				<div v-else-if="store.loading">
					Authenticatie laden...
				</div>
				<slot></slot>
			</ClientOnly>
		</template>
	</DefaultGrid>
</template>

<script setup lang="ts">
	import {computed, watch} from 'vue';
	import {useRoute} from 'vue-router';
	import {useAuthStore} from '~/store/global/auth';
	import DefaultGrid from '~/components/layout/DefaultGrid.vue';
	import SidebarLogo from '~/components/layout/SidebarLogo.vue';
	import {initKeycloak, KeycloakState} from '~/store/global/keycloak';
	import SidebarNav from '~/components/layout/SidebarNav.vue';
	import {useCompanyContextStore} from '~/store/company/companyContext';
	import {useUserDetailsStore} from '~/store/global/userdetails';

	const route = useRoute();
	const keycloak = initKeycloak();
	const store = useAuthStore();
	const companyContextStore = useCompanyContextStore();
	const userDetailsStore = useUserDetailsStore();

	const companyId = computed(() => Number(route.params.companyId as string | undefined));
	const rieIdFromRoute = computed(() => route.params.rieId as string | undefined);
	const rieId = computed(() => rieIdFromRoute.value || companyContextStore.currentRieId);

	const companyName = computed(() => companyContextStore.getCompanyName(companyId.value));
	const rieName = computed(() => {
		let fallback: string | undefined;
		if (rieIdFromRoute.value) {
			fallback = '...';
		}
		return companyContextStore.getRieName(companyId.value, Number(rieId.value)) ?? fallback;
	});

	watch(
		rieIdFromRoute, (newId) => {
			if (newId === undefined) return;
			const rieIdAsNumber = parseInt(newId);
			if (isNaN(rieIdAsNumber)) return;
			companyContextStore.currentRieId = rieIdAsNumber;
		},
		{
			immediate: true
		}
	);

	watch(
		companyId, (newId) => {
			// Reset stored rieId, because the company has changed
			if (!rieIdFromRoute.value) {
				companyContextStore.currentRieId = undefined;
			}
			// If there's no new companyId do nothing
			if (newId === undefined || isNaN(newId)) return;
			// Different companyId: update related data in store.
			companyContextStore.fetchCompanyContext(newId);
		},
		{
			immediate: true
		}
	);
</script>
